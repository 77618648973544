<template>
    <v-btn icon :width="size" :height="size"
        ><v-icon class="hint-icon" :size="size" :class="{ 'white-color': white }" @click="openSidebar(hintId)"
            >mdi-help-circle</v-icon
        ></v-btn
    >
</template>

<script>
export default {
    name: 'HintIcon',
    props: {
        hintId: {
            type: String,
            default: null,
        },
        white: {
            type: Boolean,
        },
        size: {
            type: Number,
            default: 24,
        },
    },
    computed: {},
    components: {},
    data: () => ({}),
    methods: {
        openSidebar() {
            this.$store.commit('setIsHintSidebar', true) //открываем сайдбар
            this.$store.commit('setSidebarHintId', this.hintId) //проставляем id подсказки которая отобразится в сайдбаре
        },
    },
    mounted() {},
}
</script>

<style lang="sass" scoped>
@import '@/vars.sass'
.hint-icon
    color: $hint-icon-color !important
    opacity: 0.38
    &.white-color
        color: white !important
        opacity: 0.5
.hint-icon:hover
    opacity: 1
    color: $hint-icon-hover-color !important
    cursor: pointer
</style>
