import { getInstance } from '@/auth/authWrapper'
import cfg from '@root/config'
import { ApiWrapper, Connector } from '../../types/main'
import { getError } from '../../utils'

export async function sendMessage(
    connectorId: string,
    clientIds: Array<number>,
    text: string
): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/altegio/sendMessage`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            connectorId,
            clientIds,
            text,
        }),
    }
    const res = await fetch(url.toString(), options)
    const error = await getError(res)
    const data = await res.json()
    return { data, error: error }
}
export async function getClientByPhone(connectorId: string, phone: string): Promise<ApiWrapper<any | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/altegio/clientByPhone`)
    const params: any = { connectorId, phone }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    const data = await res.json()
    const error = await getError(res)
    return { data, error: error }
}
export async function login({ connector, projectId }: any): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/altegio/login`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            connector,
            projectId,
            isSimpleAuth: true,
        }),
    }
    const res = await fetch(url.toString(), options)
    const error = await getError(res)
    const data = await res.json()
    return { data, error: error }
}
