import { getInstance } from '@/auth/authWrapper'
import cfg from '@root/config'
import { Connector } from '../../types/main'
import { getError } from '../../utils'

export async function login(connector: Connector, credentials: { authKeyApi: string }) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/arnica/login`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            connector,
            authKeyApi: credentials.authKeyApi,
        }),
    }
    const res = await fetch(url, options)
    if (res.status === 200) {
        return true
    } else {
        return false
    }
}
export async function saveEmailAndPassword(connectorId: string, email: string, password: string) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/arnica/saveEmailAndPassword`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            connectorId,
            email,
            password,
        }),
    }
    const res = await fetch(url, options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
