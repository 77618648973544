import { getInstance } from '@/auth/authWrapper'
import cfg from '@root/config'
import { Connector } from '../../types/main'

export async function login(connector: Connector, credentials: { xApiKey: string }) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/sonline/login`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            connector,
            xApiKey: credentials.xApiKey,
        }),
    }
    const res = await fetch(url, options)
    if (res.status === 200) {
        return true
    } else {
        return false
    }
}
export async function getSalons(connectorId: string) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/sonline/salons`)
    const params: any = { connectorId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    return res.json()
}
