import { getInstance } from '@/auth/authWrapper'
import cfg from '@root/config'
import { ApiWrapper, Connector } from '../../types/main'
import { getError } from '../../utils'

export async function login(connector: Connector, credentials: { token: string; url: string }) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/ipTelephony/prostyiZvonki/login`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            connector,
            credentials,
        }),
    }
    const res = await fetch(url, options)
    if (res.status === 200) {
        return true
    } else {
        return false
    }
}

export async function makeCall({
    connectorId,
    phone,
    userId,
}: {
    connectorId: string
    phone: string
    userId?: string
}): Promise<ApiWrapper<{ dhUsers: any[]; megaphoneUsers: any[] } | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/ipTelephony/prostyiZvonki/makeCall`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            connectorId,
            callTo: phone,
            userId: userId,
        }),
    }
    const res = await fetch(url.toString(), options)
    const error = await getError(res)
    const data = await res.json()

    return { data, error: error }
}
