import { getInstance } from '@/auth/authWrapper'
import cfg from '@root/config'
import { Connector } from '../../types/main'

export async function login(connector: Connector) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/moiSklad/login`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            connector,
        }),
    }
    const res = await fetch(url, options)
    if (res.status < 300) {
        return true
    } else {
        return false
    }
}
export async function uploadFile(connector: Connector, file: File) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/moiSklad/loadDataFromFiles`

    let formData = new FormData()
    formData.append('projectId', connector.projectId)
    formData.append('connectorId', connector.connectorId)
    formData.append('connectorType', connector.connectorType)
    formData.append('files', file, file.name)
    const options = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: formData,
    }
    const res = await fetch(url, options)
    return res
}
