import cfg from '../../../config'
import { getInstance } from '../../auth'
import { Connector } from '../../types/main'
import { getError } from '../../utils'

export async function login(
    connector: Connector,
    credentials: { username: string; endpoint: string; password: string }
) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/ruslan1C/login`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            connector,
            credentials,
        }),
    }
    const res = await fetch(url, options)
    if (res.status === 200) {
        return true
    } else {
        return false
    }
}
export async function getSalons(connectorId: string) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/ruslan1C/getSalons`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            connectorId,
        }),
    }
    const res = await fetch(url, options)
    if (res.status > 300) {
        return { data: null, error: getError(res) }
    } else {
        return { data: await res.json(), error: null }
    }
}
