import { getInstance } from '@/auth/authWrapper'
import cfg from '@root/config'
/**
 *
 * @param {*} projectId
 * @param {{url, user, password, apiKey, apliPass}} credentials
 * @returns
 */
export async function login(connector, credentials) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/universeSoft/login`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            connector,
            url: credentials.url,
            user: credentials.user,
            password: credentials.password,
            apiKey: credentials.apiKey,
            apiPass: credentials.apiPass,
        }),
    }
    const res = await fetch(url, options)
    if (res.status === 200) {
        return true
    } else {
        return false
    }
}
export async function getCompanies(connectorId) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/universeSoft/getCompanies`)
    const params = { connectorId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url, options)
    const parsedRes = await res.json()
    return parsedRes
}
